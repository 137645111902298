import dynamic from 'next/dynamic';
import { useEffect, useRef, useState } from 'react';

const HeaderMainPartMobile = dynamic(() => import('./HeaderMainPartMobile'), { ssr: false });
const HeaderMainPartDesktop = dynamic(() => import('./HeaderMainPartDesktop'), { ssr: false });

const FIXED_HEIGHT = 96;

const HeaderMainPart = ({ matches }: { matches: boolean }) => {
    const mobileHeaderRef = useRef<HTMLDivElement>(null);

    const [isSearchFixed, setIsSearchFixed] = useState(false);

    useEffect(() => {
        let observer: IntersectionObserver;

        observer = new IntersectionObserver(([entry]) => {
            setIsSearchFixed(!entry.isIntersecting);
        });

        if (mobileHeaderRef?.current) {
            observer.observe(mobileHeaderRef.current);
        }

        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, []);

    return (
        <>
            <div
                ref={mobileHeaderRef}
                css={{
                    height: matches ? FIXED_HEIGHT : 0,
                    minHeight: matches ? FIXED_HEIGHT : 0,
                }}
            />

            {matches ? (
             <div css={{minHeight: '56px'}}>  <HeaderMainPartMobile isSearchFixed={isSearchFixed} /></div>
            ) : (
                <HeaderMainPartDesktop isSearchFixed={isSearchFixed} />
            )}
        </>
    );
};

export default HeaderMainPart;
